<template>
  <v-container fluid class="px-md-5 px-2">
    <!-- Bio Section -->
    <v-row id="bio" class="my-6 py-6" style="background-color: #f7efed">
      <v-col cols="12" lg="3" md="4">
        <v-img src="../assets/ali-bayani.jpg" class="rounded-img" />
      </v-col>
      <v-col cols="12" lg="8" md="7">
        <h2 class="mb-3">Biography</h2>
        <p>
          Welcome! I am Ali Bayani, a computer engineer specializing in artificial intelligence and machine learning. I hold a B.Sc. in Computer Engineering from Islamic Azad University, Tabriz Branch, where I graduated with a GPA of 3.74/4.0.
        </p>
        <p>
          Currently, I am researching and writing research and conference papers, with a focus on machine learning, deep learning, NLP, computer vision, and software engineering. I have contributed to various publications and conferences in these areas.
        </p>
        <p>
          I have practical experience programming in Python, C/C++, and Java, and I am proficient with tools and frameworks such as TensorFlow, PyTorch, and Scikit-learn. I explore applications in Medical Imaging, Biomedical Signal Processing, Disease Diagnosis, Healthcare, Software Engineering, IoT, and Finance.
        </p>
        <p>
          <v-row class="social-links">
            <v-col cols="auto" class="social-item">
              <v-icon size="30px" color="#171515">mdi-github</v-icon><a href="https://github.com/aliebayani">aliebayani</a>
            </v-col>
            <v-col cols="auto" class="social-item">
              <img src="../assets/Google_Scholar_logo.svg.png" alt="Kaggle Icon" style="width: 25px; height: 25px; vertical-align: middle; margin-right: 2px;"><a href="https://scholar.google.com/citations?user=bACdbPYAAAAJ&hl=en">Ali Bayani</a>
            </v-col>
            <v-col cols="auto" class="social-item">
              <v-icon size="30px" color="#0072b1">mdi-linkedin</v-icon><a href="https://www.linkedin.com/in/ali-bayani-a13505209">Ali Bayani</a>
            </v-col>
            <v-col cols="auto" class="social-item">
              <img src="../assets/Kaggle_Logo.svg.png" alt="Kaggle Icon" style="width: 50px; height: 25px; vertical-align: middle; margin-right: 2px;"><a href="https://www.kaggle.com/alibyni">alibyni</a>
            </v-col>
          </v-row>
        </p>
      </v-col>
    </v-row>

    <!-- Publications Section -->
    <v-row id="publication" class="my-6 py-6" style="background-color: #f7efed">
      <v-col cols="12">
        <h2>Publications</h2>
        <ul>
          <li>
            <a href="https://doi.org/10.14814/phy2.16182">LDCNN: A new technique for Arrhythmia Detection from ECG signals using Linear Deep Convolutional Neural Network.</a>
            <br><i>Physiological Reports - Wiley Online Library</i>
            <v-row>
            <v-col cols="15" sm="4">
              <v-img style="margin-top: 30px;" src="../assets/Publication.jpg" class="publication1-img"/>
            </v-col>
            <v-col cols="15" sm="8">
              <v-img src="../assets/CNN_Arc.png" class="publication2-img"/>
            </v-col>
            </v-row>
          </li>
          <li>
            <a href="https://doi.org/10.1109/SCIoT62588.2024.10570107">Integrating CNN-LSTM Networks with Statistical Filtering Techniques for Intelligent IoT Intrusion Detection.</a>
            <br><i>2024 8th International Conference on Smart Cities, Internet of Things and Applications (SCIoT) - IEEE</i>
          </li>
        </ul>
      </v-col>
    </v-row>

    <!-- Projects & Researches Section -->
    <v-row id="projects" class="my-6 py-6" style="background-color: #f7efed">
      <v-col cols="12">
        <h2>Projects & Researches</h2>
        <ul>
          <li>Portfolio optimization and consulting system development for portfolio asset allocation using deep reinforcement learning. 2024</li>
          <li>Advancements in human disease detection using deep learning and machine learning approaches. 2024</li>
          <li>Protein secondary structure prediction using LSTM, encoder-decoder Seq2Seq, and GAN. 2023</li>
          <li>Enhancing software architecture descriptions with graph theory. 2023</li>
          <li>Arrhythmia detection technique development using ECG signals with LDCNN for cardiovascular diseases. 2023</li>
          <li>Reading comprehension-based question answering system using transformer-based learning. 2023</li>
          <li>Deep clustering of social media texts using liquid neural networks. 2023</li>
          <li>Home Delivery Food System Application. 2022 <a href="https://alibayani.online/upload/Ali_Bayani_Version7.pdf">Download PDF</a></li>
          <li>Presentation: Neuralink 2022</li>
          <v-img src="../assets/neuralink.png" class="responsive-img mt-4" />
          <li>Recommender system for electronic customer relationship management. 2022</li>
          <li>Presentation: Familiarity with the concepts of classification and clustering. 2021</li>
          <li>Diagnosis and classification of retinal diseases with deep learning algorithms. 2021</li>
          <v-row>
            <v-col cols="12" sm="6">
              <v-img src="../assets/eye1.jpeg" class="responsive-img" />
            </v-col>
          </v-row>
        </ul>
      </v-col>
    </v-row>

    <!-- Resume Section -->
    <v-row id="resume" class="my-6 py-6" style="background-color: #f7efed">
      <v-col cols="12">
        <h2>My CV
         <br> <a href="https://alibayani.online/upload/CV.pdf" class="text-subtitle-2">Download CV</a></h2>
      </v-col>
    </v-row>

    <!-- Contact Section -->
    <v-row id="contact" class="my-6 py-6" style="background-color: #f7efed">
      <v-col cols="12">
        <h2>Contact</h2>
        <ul class="contact-list">
          <li><v-icon size="30px" color="#f1485b">mdi-email-arrow-right</v-icon><a href="mailto:aliwbayani@gmail.com">aliwbayani@gmail.com</a></li>
          <li><v-icon size="30px" color="#f1485b">mdi-email-arrow-right</v-icon><a href="mailto:alibayani@iaut.ac.ir">alibayani@iaut.ac.ir</a></li>
          </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
  name: "resumePage",
  components: {
    Icon,
  },
};
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 22px;
  font-family: 'InterVariable', sans-serif;
}

p {
  font-size: 17px;
  line-height: 1.6rem;
}

.rounded-img {
  border-radius: 15px;
  max-width: 100%;
}

.responsive-img {
  max-width: 30%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.contact-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.contact-list li {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.publication1-img {
  max-width: 70%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.publication2-img {
  max-width: 90%;
  height: 40vh;
  object-fit: contain;
  border-radius: 10px;
}

.social-links {
  margin-top: 1rem; /* Adjust top margin as needed */
}

.social-item {
  display: flex;
  align-items: center;
  margin-right: 1rem; /* Adjust right margin for spacing between items */
}

.social-item a {
  margin-left: 0.5rem; /* Adjust left margin for spacing between icon and text */
}

.social-item:last-child {
  margin-right: 0; /* Remove margin on the last item */
}

a:link {
  color: #f1485b;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: #f1485b;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: #f1485b;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: #33546d;
  background-color: transparent;
  text-decoration: underline;
}

</style>
