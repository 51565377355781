import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticClass:"pa-0 ma-0"},[_c(VAppBar,{staticClass:"elevation-3 appBar",staticStyle:{"background-color":"#7fa7b8"},attrs:{"fixed":"","app":""}},[_c(VRow,{staticClass:"align-center"},[_c(VCol,{attrs:{"cols":"12","lg":"3","md":"4"}},[_c('h1',{staticClass:"app-title ml-5"},[_vm._v("Ali Bayani")])]),_c(VCol,{attrs:{"cols":"12","lg":"9","md":"8"}},[_c(VList,{staticClass:"app-menu d-flex justify-space-between",staticStyle:{"background-color":"#7fa7b8"},attrs:{"dense":""}},_vm._l((_vm.menuItems),function(item){return _c(VListItem,{key:item.text,staticClass:"pa-0"},[_c('a',{staticClass:"app-menu-link",attrs:{"href":item.href}},[_vm._v(_vm._s(item.text))])])}),1)],1)],1)],1),_c(VMain,{staticClass:"ma-0 pa-0"},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }