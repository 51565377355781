<template>
  <v-app class="pa-0 ma-0">
    <v-app-bar class="elevation-3 appBar" fixed app style="background-color: #7fa7b8;">
      <v-row class="align-center">
        <v-col cols="12" lg="3" md="4">
          <h1 class="app-title ml-5">Ali Bayani</h1>
        </v-col>
        <v-col cols="12" lg="9" md="8">
          <v-list class="app-menu d-flex justify-space-between" style="background-color: #7fa7b8;" dense>
            <v-list-item v-for="item in menuItems" :key="item.text" class="pa-0">
              <a :href="item.href" class="app-menu-link">{{ item.text }}</a>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="ma-0 pa-0">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    menuItems: [
      { text: 'Biography', href: '#bio' },
      { text: 'Publications', href: '#publication' },
      { text: 'Researches', href: '#projects' },
      { text: 'My CV', href: '#resume' },
      { text: 'Contact', href: '#contact' }
    ]
  })
};
</script>

<style>
/* Global Styles */
* {
  font-family: 'InterVariable', sans-serif !important;
}

::-webkit-scrollbar {
  width: 0;
}

.app-title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  margin-left: 1rem;
}

.app-menu {
  background-color: transparent;
}

.app-menu-link {
  color: black !important;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: 5px;
}

.app-menu-link:hover {
  border-bottom: solid 2px black;
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  body {
    margin-right: calc(100vw - 100%);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .app-title {
    font-size: 1.75rem;
  }

  .app-menu-link {
    font-size: 14px;
  }

  .appBar {
    padding: 8px;
  }
}
</style>
